import * as React from "react";
import AssetPage from "../../components/asset-page";

const FlarePage = () => (
  <AssetPage
    asset="Flare"
    ticker="FLR"
    description={[
      "Flare is the blockchain for data.",
      "It is a Layer 1 like Ethereum, with added data acquisition functionality. Flare can therefore offer developers decentralized access to high-integrity data from other chains and the internet. This enables the creation of dapps with new use cases and monetization models. It also allows these dapps to serve multiple chains through a single deployment.",
      "Flare is Ethereum Virtual Machine (EVM) based, meaning any applications written in Solidity to run on any other EVM chain can also be used on Flare."
    ]}
    features={[
      {
        title: "Wrap your FLR",
        text: "Easily wrap and unwrap FLR directly in the app.",
        url: "https://support.bifrostwallet.com/en/articles/6545558-wrap-and-delegate-flare",
        linkText: "How do I wrap FLR?"
      },
      {
        title: "Delegate voting power",
        text: "Manage FTSO provider delegations natively.",
        url: "https://support.bifrostwallet.com/en/articles/6545558-wrap-and-delegate-flare",
        linkText: "How do I delegate?"
      },
      {
        title: "Activate autoclaiming",
        text: "Bifrost Claimbot is a seamless solution that automatically handle claims of FTSO rewards and Flaredrops.",
        url: "https://support.bifrostwallet.com/en/articles/6908937-how-to-enable-automatic-claiming-in-bifrost-wallet",
        linkText: "Learn how to enable automatic claiming"
      },
    ]}
  />
);

export default FlarePage;
